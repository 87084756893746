import * as Sentry from "@sentry/nextjs";
import axios, { AxiosResponse } from "axios";

import { ApproveDataSuccess } from "@/api/returns/[returnId]/approve-data";
import { ApproveFormSuccess } from "@/api/returns/[returnId]/approve-form";
import { AssignReturnResponse, AssignSuccess } from "@/api/returns/[returnId]/assign";
import { DeactivateDuplicateTransactionsSuccess } from "@/api/returns/[returnId]/deactivate-duplicate-transactions";
import { DuplicateTransactionsSuccess } from "@/api/returns/[returnId]/duplicate-transactions";
import { ReturnFormSuccess } from "@/api/returns/[returnId]/form";
import { GetReturnWithUsersResponse } from "@/api/returns/[returnId]/get";
import { ReturnFilesSuccess } from "@/api/returns/[returnId]/get-files";
import { MarkFiledSuccess } from "@/api/returns/[returnId]/mark-filed";
import { MarkPaymentSuccess } from "@/api/returns/[returnId]/mark-payment-done";
import { SetPaymentDeadlineSuccess } from "@/api/returns/[returnId]/payment-deadline";
import { TransactionSummariesSuccess } from "@/api/returns/[returnId]/transaction-summaries";
import { UnapproveDataSuccess } from "@/api/returns/[returnId]/unapprove-data";
import { UnapproveFormSuccess } from "@/api/returns/[returnId]/unapprove-form";
import { CsvGetSuccess } from "@/api/returns/csv/[csvId]/get";
import {
  FailedCSVValidationResponse,
  ProcessingResultsResponse,
} from "@/api/returns/csv/[csvId]/processing-result";
import { StartCSVUploadResponse } from "@/api/returns/csv/start-upload";
import { GetAllCsvsWithUsersResponse } from "@/api/returns/csvs";
import { EfileReturnRequest, EfileReturnResponse } from "@/api/returns/e-filing/[returnId]/e-file";
import { PrepareEFileResponse } from "@/api/returns/e-filing/[returnId]/prepare";
import {
  ValidateConsentRequest,
  ValidateConsentResponse,
} from "@/api/returns/e-filing/[returnId]/validate-consent";
import { ListReturnsQuery, ListReturnsWithUsersResponse, Return } from "@/api/returns/list";
import { TransactionDuplicatesResponse } from "@/api/returns/transactions/[transactionId]/duplicates";
import { GetCountriesResponse } from "@/api/returns/transactions/countries";
import { GetRateClassesResponse } from "@/api/returns/transactions/rate-classes";
import { GetTaxRatesResponse } from "@/api/returns/transactions/tax-rates";
import { GetTaxScenariosResponse } from "@/api/returns/transactions/tax-scenarios";
import { GetWarningsResponse } from "@/api/returns/transactions/warnings";
import {
  TransactionWarningsFilter,
  TransactionWarningsResponse,
} from "@/api/returns/transactions/warnings-summary";
import { GetValidationWarningsResponse } from "@/api/returns/validation-warnings";
import { GetUploadedCsvsFilters } from "@/features/Returns/DataGatheringPage/DataGatheringPage.types";
import { FileType } from "@/hooks/returns/useReturnFiles";

import { populateClientAntiFraudValues } from "./utils";

export type ReturnsApi = {
  assignReturn: (
    assignees: string[],
    returnId: string | undefined,
    countryCode?: string,
    periodKey?: string
  ) => Promise<AssignSuccess>;
  getReturns: (filters?: ListReturnsQuery) => Promise<ListReturnsWithUsersResponse>;
  getReturnsByCsv: (csvId: string) => Promise<ListReturnsWithUsersResponse>;
  getReturn: (returnId: string) => Promise<GetReturnWithUsersResponse>;
  getReturnForm: (returnId: string) => Promise<ReturnFormSuccess>;
  getReturnFiles: (returnId: string, type: FileType[]) => Promise<ReturnFilesSuccess>;
  getTransactionSummaries: (returnId: string) => Promise<TransactionSummariesSuccess>;
  getDuplicateTransactions: (returnId: string) => Promise<DuplicateTransactionsSuccess>;
  deactivateDuplicateTransactions: (
    returnId: string
  ) => Promise<DeactivateDuplicateTransactionsSuccess>;
  approveData: (returnId: string) => Promise<ApproveDataSuccess>;
  unapproveData: (returnId: string) => Promise<UnapproveDataSuccess>;
  approveForm: (returnId: string) => Promise<ApproveFormSuccess>;
  unapproveForm: (returnId: string) => Promise<UnapproveFormSuccess>;
  markFiled: (returnId: string) => Promise<MarkFiledSuccess>;
  markPaymentDone: (returnId: string) => Promise<MarkPaymentSuccess>;
  setPaymentDeadline: (
    returnId: string | undefined,
    deadline: string
  ) => Promise<MarkPaymentSuccess>;
  uploadFile: (request: UploadFileRequest) => Promise<void>;
  getCsvMetadata: (csvId: string) => Promise<CsvGetSuccess>;
  getExportCsvSummaryUrl: (csvId: string) => string;
  getDownloadCsvTemplateUrl: (templateCode: string) => string;
  activateCsv: (returnId: string) => Promise<void>;
  deactivateCsv: (returnId: string) => Promise<void>;
  getUploadedCsvs: (filters: GetUploadedCsvsFilters) => Promise<GetAllCsvsWithUsersResponse>;
  getTransactionWarningsSummary: (
    filters: TransactionWarningsFilter
  ) => Promise<TransactionWarningsResponse>;
  getTaxScenarios: (filters: GetTransactionFieldFilter) => Promise<GetTaxScenariosResponse>;
  getWarnings: (filters: GetTransactionFieldFilter) => Promise<GetWarningsResponse>;
  getTaxRates: (filters: GetTransactionFieldFilter) => Promise<GetTaxRatesResponse>;
  getRateClasses: (filters: GetTransactionFieldFilter) => Promise<GetRateClassesResponse>;
  getTransactionsCountries: (filters: GetTransactionFieldFilter) => Promise<GetCountriesResponse>;
  getTransactionDuplicationsSources: (
    transactionId: string
  ) => Promise<TransactionDuplicatesResponse>;
  requestAuditTrail: (dateType: string, dateFrom?: string, dateTo?: string) => Promise<void>;
  validateConsent: (
    returnId: string,
    request: ValidateConsentRequest
  ) => Promise<ValidateConsentResponse>;
  eFileReturn: (id: string, request: EfileReturnRequest) => Promise<EfileReturnResponse>;
  prepareEFileReturn: (id: string, user: string) => Promise<PrepareEFileResponse>;
  getValidationWarnings: (
    filters: GetValidationWarningsFilter
  ) => Promise<GetValidationWarningsResponse>;
  startCsvUpload: (request: StartUploadRequest) => Promise<StartCSVUploadResponse>;
  uploadCsvChunk: (request: UploadChunkRequest) => Promise<void>;
  finishCsvUpload: (csvId: string) => Promise<void>;
  getValidationErrors: (csvId: string) => Promise<FailedCSVValidationResponse | undefined>;
  deleteCsv: (csvId: string) => Promise<void>;
};

export type ColumnStatus = Return["status"];

export interface StartUploadRequest {
  fileName: string;
  templateCode?: string;
}

export interface UploadFileRequest {
  returnId: string | undefined;
  fileName: string;
  type: FileType;
  file: Blob;
}

export interface UploadChunkRequest {
  csvId: string;
  chunkNumber: number;
  chunk: Blob;
}

export interface DownloadFileResponse<T> {
  content: T;
  fileName: string;
}

export interface GetTransactionsFilter {
  cursor?: string;
  limit?: number;
  item_id?: string;
  csv_name?: string;
  date_from?: string;
  date_to?: string;
  invoice_number?: string;
  status?: string;
  tax_rate?: string;
  tax_scenario?: string;
  country_code?: string;
  sort_by?: string;
  return_id?: string;
  csv_id?: string;
  warning?: string;
}

interface GetTransactionFieldFilter {
  return_id?: string;
  csv_id?: string;
}
interface GetValidationWarningsFilter {
  resource_id?: string;
}

class ReturnsApiError extends Error {
  constructor(
    readonly method: keyof ReturnsApi,
    readonly status: AxiosResponse["status"],
    readonly statusText: AxiosResponse["statusText"],
    readonly apiStatus?: string
  ) {
    super();
    this.message = `ReturnsApi ${method} failed. HTTP ${status} ${statusText}, API status "${apiStatus}"`;
    Sentry.captureException(this);
  }
}
const api: ReturnsApi = {
  async assignReturn(assignees: string[], returnId: string | undefined) {
    const { status, statusText } = await axios.post<{ data?: AssignReturnResponse }>(
      `/api/returns/${returnId}/assign`,
      {
        assignees: assignees,
      }
    );

    if (status === 200) {
      return {} as AssignSuccess;
    } else {
      throw new ReturnsApiError("assignReturn", status, statusText, undefined);
    }
  },
  async getReturns(filters) {
    const { data, status, statusText } = await axios.get<{ data?: ListReturnsWithUsersResponse }>(
      `/api/returns/list`,
      {
        params: filters,
      }
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("getReturns", status, statusText, data.data?.status);
    }
  },
  async getReturnsByCsv(csvId: string) {
    const { data, status, statusText } = await axios.get<{ data?: ListReturnsWithUsersResponse }>(
      `/api/returns/list?csv_id=${csvId}`
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("getReturnsByCsv", status, statusText, data.data?.status);
    }
  },
  async getTransactionSummaries(returnId: string) {
    const { data, status, statusText } = await axios.get<{ data?: TransactionSummariesSuccess }>(
      `/api/returns/${returnId}/transaction-summaries`
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("getTransactionSummaries", status, statusText, data.data?.status);
    }
  },
  async getDuplicateTransactions(returnId: string) {
    const { data, status, statusText } = await axios.get<{ data?: DuplicateTransactionsSuccess }>(
      `/api/returns/${returnId}/duplicate-transactions`
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("getDuplicateTransactions", status, statusText, data.data?.status);
    }
  },
  async deactivateDuplicateTransactions(returnId: string) {
    const { status, statusText } = await axios.get<{
      data?: DeactivateDuplicateTransactionsSuccess;
    }>(`/api/returns/${returnId}/deactivate-duplicate-transactions`);

    if (status === 200) {
      return {} as DeactivateDuplicateTransactionsSuccess;
    } else {
      throw new ReturnsApiError("deactivateDuplicateTransactions", status, statusText);
    }
  },
  async getReturn(returnId: string) {
    const { data, status, statusText } = await axios.get<{ data?: GetReturnWithUsersResponse }>(
      `/api/returns/${returnId}/get`
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("getReturn", status, statusText, data.data?.status);
    }
  },
  async getReturnForm(returnId: string) {
    const { data, status, statusText } = await axios.get<{ data?: ReturnFormSuccess }>(
      `/api/returns/${returnId}/form`
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("getReturnForm", status, statusText, data.data?.status);
    }
  },
  async getReturnFiles(returnId: string, type) {
    const { data, status, statusText } = await axios.get<{ data?: ReturnFilesSuccess }>(
      `/api/returns/${returnId}/get-files?type=${type?.join(",")}`
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("getReturnFiles", status, statusText, data.data?.status);
    }
  },
  async approveData(returnId: string) {
    const { status, statusText } = await axios.get<{ data?: ApproveDataSuccess }>(
      `/api/returns/${returnId}/approve-data`
    );

    if (status === 200) {
      return {} as ApproveDataSuccess;
    } else {
      throw new ReturnsApiError("approveData", status, statusText);
    }
  },
  async unapproveData(returnId: string) {
    const { status, statusText } = await axios.get<{ data?: UnapproveDataSuccess }>(
      `/api/returns/${returnId}/unapprove-data`
    );

    if (status === 200) {
      return {} as UnapproveDataSuccess;
    } else {
      throw new ReturnsApiError("unapproveData", status, statusText);
    }
  },
  async approveForm(returnId: string) {
    const { status, statusText } = await axios.get<{ data?: ApproveFormSuccess }>(
      `/api/returns/${returnId}/approve-form`
    );

    if (status === 200) {
      return {} as ApproveFormSuccess;
    } else {
      throw new ReturnsApiError("approveForm", status, statusText);
    }
  },
  async unapproveForm(returnId: string) {
    const { status, statusText } = await axios.get<{ data?: UnapproveFormSuccess }>(
      `/api/returns/${returnId}/unapprove-form`
    );

    if (status === 200) {
      return {} as UnapproveFormSuccess;
    } else {
      throw new ReturnsApiError("unapproveForm", status, statusText);
    }
  },
  async markFiled(returnId: string) {
    const { status, statusText } = await axios.get<{ data?: MarkFiledSuccess }>(
      `/api/returns/${returnId}/mark-filed`
    );

    if (status === 200) {
      return {} as MarkFiledSuccess;
    } else {
      throw new ReturnsApiError("markFiled", status, statusText);
    }
  },
  async markPaymentDone(returnId: string) {
    const { status, statusText } = await axios.post<{ data?: MarkPaymentSuccess }>(
      `/api/returns/${returnId}/mark-payment-done`
    );

    if (status === 200) {
      return {} as MarkPaymentSuccess;
    } else {
      throw new ReturnsApiError("markPaymentDone", status, statusText);
    }
  },
  async setPaymentDeadline(returnId: string | undefined, deadline: string) {
    const { status, statusText } = await axios.post<{ data?: SetPaymentDeadlineSuccess }>(
      `/api/returns/${returnId}/payment-deadline`,
      { deadline }
    );

    if (status === 200) {
      return {} as SetPaymentDeadlineSuccess;
    } else {
      throw new ReturnsApiError("setPaymentDeadline", status, statusText);
    }
  },
  async uploadFile(request: UploadFileRequest) {
    const form = new FormData();
    form.append("file", request.file);
    form.append("fileName", request.fileName);
    form.append("type", request.type);

    const { status, statusText } = await axios.post(
      `/api/returns/${request.returnId}/upload-file`,
      form,
      {
        maxContentLength: 5_242_880, // 5mb
        maxBodyLength: 5_242_880, // 5mb
        headers: {
          "Content-Type": "multipart/form-data",
        },
        validateStatus: () => true,
      }
    );

    if (status === 200) {
      return;
    } else {
      throw new ReturnsApiError("uploadFile", status, statusText);
    }
  },
  async getCsvMetadata(csvId: string) {
    const { data, status, statusText } = await axios.get<{ data?: CsvGetSuccess }>(
      `/api/returns/csv/${csvId}/get`
    );

    if (data.data?.status === "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("getCsvMetadata", status, statusText, data.data?.status);
    }
  },
  async activateCsv(csvId: string) {
    const { data, status, statusText } = await axios.get(`/api/returns/csv/${csvId}/activate`);

    if (data.data?.status == "success") {
      return;
    } else {
      throw new ReturnsApiError("activateCsv", status, statusText, data.data?.status);
    }
  },
  async deactivateCsv(csvId: string) {
    const { data, status, statusText } = await axios.get(`/api/returns/csv/${csvId}/deactivate`);

    if (data.data?.status == "success") {
      return;
    } else {
      throw new ReturnsApiError("deactivateCsv", status, statusText, data.data?.status);
    }
  },
  getExportCsvSummaryUrl(csvId: string) {
    return `/api/returns/csv/${csvId}/export-summary`;
  },
  getDownloadCsvTemplateUrl(templateCode: string) {
    return "/api/returns/csv/template?template_code=" + templateCode;
  },
  async getUploadedCsvs(filters) {
    const { data, status, statusText } = await axios.get<{ data?: GetAllCsvsWithUsersResponse }>(
      `api/returns/csvs`,
      {
        params: filters,
      }
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("getUploadedCsvs", status, statusText, data.data?.status);
    }
  },
  async getTransactionWarningsSummary(filters) {
    const { data, status, statusText } = await axios.get<{ data?: TransactionWarningsResponse }>(
      `/api/returns/transactions/warnings-summary`,
      {
        params: filters,
      }
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError(
        "getTransactionWarningsSummary",
        status,
        statusText,
        data.data?.status
      );
    }
  },
  async getTaxRates(filters) {
    const { data, status, statusText } = await axios.get<{ data?: GetTaxRatesResponse }>(
      `/api/returns/transactions/tax-rates`,
      {
        params: filters,
      }
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("getTaxRates", status, statusText, data.data?.status);
    }
  },
  async getRateClasses(filters) {
    const { data, status, statusText } = await axios.get<{ data?: GetRateClassesResponse }>(
      `/api/returns/transactions/rate-classes`,
      {
        params: filters,
      }
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("getRateClasses", status, statusText, data.data?.status);
    }
  },
  async getTransactionsCountries(filters) {
    const { data, status, statusText } = await axios.get<{ data?: GetCountriesResponse }>(
      `/api/returns/transactions/countries`,
      {
        params: filters,
      }
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("getTransactionsCountries", status, statusText, data.data?.status);
    }
  },
  async getTaxScenarios(filters) {
    const { data, status, statusText } = await axios.get<{ data?: GetTaxScenariosResponse }>(
      `/api/returns/transactions/tax-scenarios`,
      {
        params: filters,
      }
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("getTaxScenarios", status, statusText, data.data?.status);
    }
  },
  async getWarnings(filters) {
    const { data, status, statusText } = await axios.get<{ data?: GetWarningsResponse }>(
      `/api/returns/transactions/warnings`,
      {
        params: filters,
      }
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("getWarnings", status, statusText, data.data?.status);
    }
  },
  async getTransactionDuplicationsSources(transactionId: string) {
    const { data, status, statusText } = await axios.get<{ data?: TransactionDuplicatesResponse }>(
      `/api/returns/transactions/${transactionId}/duplicates`
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError(
        "getTransactionDuplicationsSources",
        status,
        statusText,
        data.data?.status
      );
    }
  },
  async requestAuditTrail(dateType, dateFrom, dateTo) {
    const { status, statusText } = await axios.post("/api/returns/request-audit-trail", {
      dateType,
      dateFrom,
      dateTo,
    });

    if (status === 200) {
      return;
    } else {
      throw new ReturnsApiError("requestAuditTrail", status, statusText);
    }
  },
  async validateConsent(returnId: string, request: ValidateConsentRequest) {
    const { data, status, statusText } = await axios.post<{ data?: ValidateConsentResponse }>(
      `/api/returns/e-filing/${returnId}/validate-consent`,
      request,
      {
        validateStatus: (status) => status === 200 || status === 400,
      }
    );

    if (data.data) {
      return data.data;
    } else {
      throw new ReturnsApiError("validateConsent", status, statusText);
    }
  },
  async eFileReturn(id: string, request: EfileReturnRequest) {
    populateClientAntiFraudValues(request.user, request.e_file_payload);

    const { data, status, statusText } = await axios.post<{ data?: EfileReturnResponse }>(
      `/api/returns/e-filing/${id}/e-file`,
      request
    );
    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("eFileReturn", status, statusText, data.data?.status);
    }
  },
  async prepareEFileReturn(id: string, user: string) {
    const prepare_payload = {};
    populateClientAntiFraudValues(user, prepare_payload);

    const { data, status, statusText } = await axios.post<{ data?: PrepareEFileResponse }>(
      `/api/returns/e-filing/${id}/prepare`,
      { prepare_payload },
      {
        validateStatus: (status) => status === 200 || status === 400,
      }
    );
    if (data.data) {
      return data.data;
    } else {
      throw new ReturnsApiError("prepareEFileReturn", status, statusText, "failed");
    }
  },
  async getValidationWarnings(filters) {
    const { data, status, statusText } = await axios.get<{ data?: GetValidationWarningsResponse }>(
      `/api/returns/validation-warnings`,
      {
        params: filters,
      }
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("getValidationWarnings", status, statusText, data.data?.status);
    }
  },
  async startCsvUpload(request: StartUploadRequest) {
    const { data, status, statusText } = await axios.post<{ data?: StartCSVUploadResponse }>(
      `/api/returns/csv/start-upload`,
      request
    );

    if (data.data?.status == "success") {
      return data.data;
    } else {
      throw new ReturnsApiError("startCsvUpload", status, statusText, data.data?.status);
    }
  },
  async uploadCsvChunk(request: UploadChunkRequest) {
    const form = new FormData();
    form.append("chunk", request.chunk);

    const { status, statusText } = await axios.post(
      `/api/returns/csv/${request.csvId}/upload-chunk?chunkNumber=${request.chunkNumber}`,
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        validateStatus: () => true,
      }
    );

    if (status === 200) {
      return;
    } else {
      throw new ReturnsApiError("uploadCsvChunk", status, statusText);
    }
  },
  async finishCsvUpload(csvId: string) {
    const { data, status, statusText } = await axios.post(
      `/api/returns/csv/${csvId}/finish-upload`
    );

    if (status === 200) {
      return;
    } else {
      throw new ReturnsApiError("finishCsvUpload", status, statusText, data.data?.status);
    }
  },
  async getValidationErrors(csvId: string) {
    const { data, status, statusText } = await axios.get<ProcessingResultsResponse>(
      `/api/returns/csv/${csvId}/processing-result`,
      {
        validateStatus: (status) => status === 200 || status === 400,
      }
    );
    if (status === 200) {
      return;
    } else if (status === 400) {
      return data.data;
    } else {
      throw new ReturnsApiError("getValidationErrors", status, statusText);
    }
  },
  async deleteCsv(csvId: string) {
    const { data, status, statusText } = await axios.delete(`/api/returns/csv/${csvId}/delete`);

    if (status === 200) {
      return;
    } else {
      throw new ReturnsApiError("deleteCsv", status, statusText, data.data?.status);
    }
  },
};

export default api;
