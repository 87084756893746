import { CountryData } from "@fonoa/data-access/countries";
import moment from "moment";
import { NextApiRequest } from "next";
import { RefObject } from "react";

import { localStorageKeys } from "@/lib/utils";

export const fakeCsvProcessingProgress: (fileSize: number, currentProgress: number) => number = (
  fileSize,
  currentProgress
) => {
  if (fileSize > 20_000_000) return currentProgress + 0.01;
  else return currentProgress + 0.1;
};

export const printFormPreview = async (ref: RefObject<HTMLDivElement>, documentTitle: string) => {
  const elementToPrint = ref.current;

  if (!elementToPrint) {
    return;
  }

  const printWindow = window.open();

  if (!printWindow) {
    return;
  }

  printWindow.document.write(
    "<style>body { -webkit-print-color-adjust: exact; print-color-adjust: exact; }</style>"
  );
  Array.from(document.querySelectorAll("style,link[rel=stylesheet]")).forEach((css) =>
    printWindow.document.write(css.outerHTML)
  );
  printWindow.document.write(elementToPrint.innerHTML);
  printWindow.document.title = documentTitle;
  printWindow.document.close();
  printWindow.focus();

  printWindow.onload = () => {
    printWindow.print();

    setTimeout(() => {
      printWindow.close();
    }, 500);
  };
};

export const beforeUnloadListener = (event: Event) => {
  event.preventDefault();
  return "Are you sure you want to leave? Your CSV upload is still in progress.";
};

export const saveUrlAs = (url: string) => {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  document.body.appendChild(a);

  a.click();
  document.body.removeChild(a);
};

export function getCountryOptions(countries: CountryData[]) {
  return countries.map((country) => ({
    value: country.id,
    label: getCountryOptionName(country),
    country: country.name,
  }));
}

export function getCountryOptionName(country?: CountryData) {
  return country ? country.name : "";
}

export function getHeaderValue(req: NextApiRequest, key: string) {
  const value = req.headers[key];
  if (typeof value === "string") {
    return value;
  } else if (Array.isArray(value)) {
    return value.join(",");
  }

  return "";
}

export function populateClientAntiFraudValues(user: string, payload?: { [key: string]: unknown }) {
  if (payload) {
    payload["app_name"] = "dashboard-app";
    payload["app_version"] = window.__NEXT_DATA__?.buildId || "dev";
    payload["client_user_agent"] = navigator.userAgent;
    payload["client_device_id"] = localStorage.getItem(localStorageKeys.device_id);
    payload["client_timezone"] = `UTC${moment(new Date()).format("Z z")}`.trim();
    payload["client_user_id"] = `user=${encodeURIComponent(user)}`;

    payload["client_window_size"] = `width=${window.innerWidth}&height=${window.innerHeight}`;

    payload[
      "client_screens"
    ] = `width=${window.screen.width}&height=${window.screen.height}&scaling-factor=1&colour-depth=${window.screen.colorDepth}`;
  }
}

export function populateServerAntiFraudValues(
  req: NextApiRequest,
  payload?: { [key: string]: unknown }
) {
  if (payload) {
    payload["client_public_ip"] = getHeaderValue(req, "x-real-ip");
    payload["client_public_port"] = req.socket.remotePort?.toString();
    payload["client_public_timestamp"] = new Date().toISOString();
  }
}
